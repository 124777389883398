export default {
  GET(state, data) {
    state.paymentTerm = { ...data }
  },
  LIST(state, data) {
    state.paymentTerms = data
  },
  SET_FORM(state, data) {
    state.paymentTermForm = { ...data }
  },
}
