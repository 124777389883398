<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import paymentTermModule from '@/store/settings/general/payment-terms'
import config from '@/views/settings/general/payment-terms/paymentTermsConfig'

export default {
  name: 'PaymentTerms',
  created() {
    this.$emit('updateMenu', 'settings-payment-terms')
  },
  setup() {
    const { MODULE_NAME, MODULE_NAME_CLONE, tableColumns } = config()
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, paymentTermModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      tableColumns,
    }

    // if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, paymentTermModule)
    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    // })
  },
}
</script>
